<template>
  <!-- begin::TenderRoutes creation -->
  <div>
    <div class="manager-title">
      <div>
        {{ $t("TENDER_ROUTES.TITLE_ADD_2") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <v-form ref="formBasic" v-model="Valid" lazy-validation class="form">
        <div class="manager-subtitle">
          {{ $t("TENDER_ROUTES.SUBTITLE") }}
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("TENDER_ROUTES.ORIGIN") }}
            </label>
            <select
              ref="selectOriginProvince"
              class="form-control select-down"
              v-model="Fields.OriginProvinceID"
              required
              @change="loadAddressOptions('origin')"
            >
              <option
                v-for="p in ProvinceOptions"
                :key="p.ProvinceID"
                :value="p.ProvinceID"
                class="text-primary"
              >
                {{ p.Name }}
              </option>
            </select>
          </div>

          <div class="form-group col-lg-2">
            &nbsp;
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("TENDER_ROUTES.DESTINATION") }}
            </label>
            <select
              class="form-control select-down"
              v-model="Fields.DestinationProvinceID"
              required
              @change="loadAddressOptions('destination')"
            >
              <option
                v-for="p in ProvinceOptions"
                :key="p.ProvinceID"
                :value="p.ProvinceID"
                class="text-primary"
              >
                {{ p.Name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 form-group">
            <label>
              {{ $t("TENDER_ROUTES.ORIGIN_ADDRESS") }}
            </label>
            <div class="row mb-3">
              <div class="col-lg-8">
                <select
                  class="form-control select-down"
                  v-model="Fields.OriginAddressID"
                  @change="onAddressChanged('origin')"
                >
                  <option
                    v-for="a in OriginAddressOptions"
                    :key="a.AddressID"
                    :value="a.AddressID"
                    class="text-primary"
                  >
                    {{
                      a.Nick.length > 0
                        ? a.Nick
                        : $t("TENDER_ROUTES.ANY_ADDRESS_IN_PROVINCE")
                    }}
                  </option>
                </select>
                <div class="v-text-field__details pt-2 pl-5 pb-0 m-0">
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message">
                        {{
                          atLeastOneAddress
                            ? " "
                            : $t("TENDER_ROUTES.AT_LEAST_ONE_ADDRESS")
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-8">
                <v-text-field
                  class="form-control"
                  :value="OriginAddressSelected.TradeName"
                  :placeholder="$t('FREQUENT_ADDRESSES.TRADE_NAME')"
                  readonly
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-8">
                <v-text-field
                  class="form-control"
                  :value="OriginAddressSelected.Street"
                  :placeholder="$t('FREQUENT_ADDRESSES.FULL_ADDRESS_2')"
                  readonly
                />
              </div>
              <div class="col-lg-4">
                <v-text-field
                  class="form-control"
                  :value="OriginAddressSelected.PostalCode"
                  :placeholder="$t('FREQUENT_ADDRESSES.POSTAL_CODE_2')"
                  readonly
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <v-text-field
                  class="form-control"
                  :value="OriginAddressSelected.City"
                  :placeholder="$t('FREQUENT_ADDRESSES.CITY')"
                  readonly
                />
              </div>
              <div class="col-lg-6">
                <v-text-field
                  class="form-control"
                  :value="getProvinceName(OriginAddressSelected.ProvinceID)"
                  :placeholder="$t('FREQUENT_ADDRESSES.PROVINCE')"
                  readonly
                />
              </div>
            </div>
          </div>

          <div class="col-lg-6 form-group">
            <label>
              {{ $t("TENDER_ROUTES.DESTINATION_ADDRESS") }}
            </label>
            <div class="row mb-3">
              <div class="col-lg-8">
                <select
                  class="form-control select-down"
                  v-model="Fields.DestinationAddressID"
                  @change="onAddressChanged('destination')"
                >
                  <option
                    v-for="a in DestinationAddressOptions"
                    :key="a.AddressID"
                    :value="a.AddressID"
                    class="text-primary"
                  >
                    {{
                      a.Nick.length > 0
                        ? a.Nick
                        : $t("TENDER_ROUTES.ANY_ADDRESS_IN_PROVINCE")
                    }}
                  </option>
                </select>
                <div class="v-text-field__details pt-2 pl-5 pb-0 m-0">
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message">
                        {{
                          differentAddress
                            ? " "
                            : $t("TENDER_ROUTES.REPEATED_ADDRESS")
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-8">
                <v-text-field
                  class="form-control"
                  :value="DestinationAddressSelected.TradeName"
                  :placeholder="$t('FREQUENT_ADDRESSES.TRADE_NAME')"
                  readonly
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-8">
                <v-text-field
                  class="form-control"
                  :value="DestinationAddressSelected.Street"
                  :placeholder="$t('FREQUENT_ADDRESSES.FULL_ADDRESS_2')"
                  readonly
                />
              </div>
              <div class="col-lg-4">
                <v-text-field
                  class="form-control"
                  :value="DestinationAddressSelected.PostalCode"
                  :placeholder="$t('FREQUENT_ADDRESSES.POSTAL_CODE_2')"
                  readonly
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <v-text-field
                  class="form-control"
                  :value="DestinationAddressSelected.City"
                  :placeholder="$t('FREQUENT_ADDRESSES.CITY')"
                  readonly
                />
              </div>
              <div class="col-lg-6">
                <v-text-field
                  class="form-control"
                  :value="
                    getProvinceName(DestinationAddressSelected.ProvinceID)
                  "
                  :placeholder="$t('FREQUENT_ADDRESSES.PROVINCE')"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
      </v-form>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="
            $router.push({
              path: '/manager/addresses',
              query: { ActiveTab: 'tender' }
            })
          "
        >
          <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          class="btn btn-pill btn-tertiary button-form"
          :disabled="
            !Valid ||
              !originProvinceValid ||
              !destinationProvinceValid ||
              !atLeastOneAddress ||
              !differentAddress
          "
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
    </div>
  </div>
  <!-- end::TenderRoutes creation -->
</template>

<script>
import AddressService from "@/core/services/api/v2/address.service";
import ProvinceService from "@/core/services/api/v2/province.service";
import TenderRouteService from "@/core/services/api/v2/tenderroute.service";

export default {
  name: "TenderRoutesNew",
  data() {
    return {
      ProvinceOptions: [],
      OriginAddressOptions: [],
      OriginAddressSelected: null,
      DestinationAddressOptions: [],
      DestinationAddressSelected: null,
      Valid: true,
      Fields: {
        OriginProvinceID: 0,
        DestinationProvinceID: 0,
        OriginAddressID: 0,
        DestinationAddressID: 0
      },
      EmptyAddress: {
        Nick: "",
        TradeName: "",
        Street: "",
        PostalCode: "",
        City: "",
        ProvinceID: 0
      }
    };
  },
  computed: {
    originProvinceValid() {
      return parseInt(this.Fields.OriginProvinceID) > 0;
    },
    destinationProvinceValid() {
      return parseInt(this.Fields.DestinationProvinceID) > 0;
    },
    atLeastOneAddress() {
      let origin = parseInt(this.Fields.OriginAddressID);
      if (isNaN(origin)) {
        origin = 0;
      }
      let destination = parseInt(this.Fields.DestinationAddressID);
      if (isNaN(destination)) {
        destination = 0;
      }

      return origin + destination > 0;
    },
    differentAddress() {
      let origin = parseInt(this.Fields.OriginAddressID);
      if (isNaN(origin)) {
        origin = -1;
      }
      let destination = parseInt(this.Fields.DestinationAddressID);
      if (isNaN(destination)) {
        destination = -2;
      }

      return origin !== destination;
    }
  },
  created() {
    this.OriginAddressSelected = Object.assign({}, this.EmptyAddress);
    this.DestinationAddressSelected = Object.assign({}, this.EmptyAddress);
    this.loadProvinceOptions();
  },
  methods: {
    loadProvinceOptions() {
      this.ProvinceOptions = [];
      this.ProvinceNames = [];

      ProvinceService.listAll().then(response => {
        response.forEach(p => {
          this.ProvinceOptions.push(p);
          this.ProvinceNames[p.ProvinceID] = p.Name;
        });
      });
    },

    loadAddressOptions(whichSide) {
      const originSide = whichSide === "origin";
      let filterByProvinceID = originSide
        ? this.Fields.OriginProvinceID
        : this.Fields.DestinationProvinceID;

      let newList = [];
      newList.push(this.EmptyAddress);

      if (originSide) {
        this.OriginAddressOptions = newList;
        this.OriginAddressSelected = this.EmptyAddress;
        this.Fields.OriginAddressID = 0;
      } else {
        this.DestinationAddressOptions = newList;
        this.DestinationAddressSelected = this.EmptyAddress;
        this.Fields.DestinationAddressID = 0;
      }

      AddressService.search("", filterByProvinceID, 0, "Nick", 0, 999, 0).then(
        rawResponse => {
          if (rawResponse !== 404) {
            rawResponse.response.Addresses.forEach(a => {
              newList.push(a);
            });

            if (originSide) {
              this.OriginAddressOptions = newList;
            } else {
              this.DestinationAddressOptions = newList;
            }
          }
        }
      );
    },

    onAddressChanged(whichSide) {
      if (whichSide === "origin") {
        this.OriginAddressSelected = this.EmptyAddress;

        for (let a = 0; a < this.OriginAddressOptions.length; a++) {
          let maybeAddress = this.OriginAddressOptions[a];

          if (maybeAddress.AddressID === this.Fields.OriginAddressID) {
            this.OriginAddressSelected = maybeAddress;
            break;
          }
        }
      } else {
        this.DestinationAddressSelected = this.EmptyAddress;

        for (let a = 0; a < this.DestinationAddressOptions.length; a++) {
          let maybeAddress = this.DestinationAddressOptions[a];

          if (maybeAddress.AddressID === this.Fields.DestinationAddressID) {
            this.DestinationAddressSelected = maybeAddress;
            break;
          }
        }
      }
    },

    getProvinceName(pID) {
      let name = this.ProvinceNames[pID];
      if (!name) {
        name = "";
      }

      return name;
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formBasic.validate()) {
        // Disable submit button
        this.Valid = false;

        // Fix optional empty AddressID
        if (parseInt(this.Fields.OriginAddressID) === 0) {
          this.Fields.OriginAddressID = null;
        }
        if (parseInt(this.Fields.DestinationAddressID) === 0) {
          this.Fields.DestinationAddressID = null;
        }

        // Send request: we get back the new ID
        TenderRouteService.createProfile(this.Fields).then(response => {
          if (response.TenderRouteID) {
            this.$bvToast.toast(" ", {
              title: this.$i18n.t("GENERIC.SAVE_OK"),
              variant: "success",
              solid: true
            });

            setTimeout(
              () =>
                this.$router.push(
                  "/manager/tenderroutes/edit/" + response.TenderRouteID
                ),
              2000
            );
          } else {
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");
            if (response === 409) {
              whyError = this.$i18n.t("TENDER_ROUTES.ERROR_409");
            } else if (response === 400) {
              whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("GENERIC.SAVE_ERROR"),
              variant: "danger",
              solid: true
            });
          }

          // Reset submit button
          this.Valid = true;
        });
      }
    }
  }
};
</script>
